import * as React from 'react'
import { useDispatch } from 'react-redux'
import { FiArrowRight } from 'react-icons/fi'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { set } from '../reducers/usecase';
import ScrollAnimation from 'react-animate-on-scroll'
import Layout from '../layouts/layout'
import Title from '../components/section/title'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/vision.module.css'

const Vision = () => {
  const dispatch = useDispatch();
  const openLayer = e => {
    e.preventDefault()
    dispatch(set(e.target.dataset.param))
  }

  const { t } = useTranslation();
  const kia = {
    title: t('visionElement1Title'),
    body: t('visionElement1Body'),
    items: [
      t('visionElement1Item1'),
      t('visionElement1Item2'),
      t('visionElement1Item3'),
    ]
  }
  const fastlane = {
    title: t('visionElement2Title'),
    body: t('visionElement2Body'),
    items: [
      t('visionElement2Item1'),
      t('visionElement2Item2'),
      // t('visionElement2Item3'),
    ]
  }
  const dob = {
    title: t('visionElement3Title'),
    body: t('visionElement3Body'),
    items: [
      t('visionElement3Item1'),
      t('visionElement3Item2'),
      t('visionElement3Item3'),
    ]
  }
  const inference = {
    title: t('visionElement4Title'),
    body: t('visionElement4Body'),
    items: [
      t('visionElement4Item1'),
      t('visionElement4Item2'),
      t('visionElement4Item3'),
    ]
  }
  return (
    <Layout>
      <div className="main-content">  
        <div className="rwt-split-area">
          <div className="row pb--125">
            <Breadcrumb
              title={t('visionTitle')}
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Vision"
              styles={styles.bg}
            />
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <Title
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = ""
                    title = "Solutions"
                    description = {t('visionBody')}
                  />
                </div>
              </div>
              {/* first */}
              <div className="rn-splite-style">
                <div className="split-wrapper split-right">
                  <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="/images/vision/kia.jpg" alt={kia.title} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{kia.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: kia.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {kia.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="image" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Second */}
              <div className="rn-splite-style">
                <div className="split-wrapper">
                  <div className="row no-gutters radius-10 align-items-center reverse">
                  <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{fastlane.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: fastlane.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {fastlane.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="face" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">                        
                          <img src="/images/vision/face.jpg" alt={fastlane.title} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Third */}
              <div className="rn-splite-style">
                <div className="split-wrapper split-right">
                  <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="/images/vision/human.jpg" alt={dob.title} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{dob.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: dob.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {dob.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="human" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              {/* Fourth */}
              <div className="rn-splite-style">
                <div className="split-wrapper">
                  <div className="row no-gutters radius-10 align-items-center reverse">
                  <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{inference.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: inference.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {inference.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="inference" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">                        
                          <img src="https://storage.googleapis.com/alphacode-company-image/inferencebg.png" alt={inference.title} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                
                <div className="flex-center ptb--50">
                  <a className="btn-default round" href="/offering"><span>{t('viewOffering')}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Vision